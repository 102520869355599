import { useRoute } from 'vue-router';
import { onMounted } from 'vue';
import { getWxConfig } from '@/service/index';
import { getIsWxClient } from "@/common/js/utils";
/* eslint-disable */

export default {
  name: 'App',

  setup() {
    console.log(useRoute().name);
    onMounted(async () => {
      if (getIsWxClient()) {
        let url = location.href.split('#')[0];
        url = encodeURIComponent(url);
        const d = await getWxConfig({
          url: url
        });
        let {
          appId,
          timestamp,
          nonceStr,
          signature
        } = d.data;
        wx.config({
          debug: false,
          // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: appId,
          // 必填，公众号的唯一标识
          timestamp: timestamp,
          // 必填，生成签名的时间戳
          nonceStr: nonceStr,
          // 必填，生成签名的随机串
          signature: signature,
          // 必填，签名
          jsApiList: ["checkJsApi", "scanQRCode"] // 必填，需要使用的 JS 接口列表

        });
        wx.ready(function () {
          console.log("wx:ready"); // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
        });
        wx.error(function (res) {
          console.log(res);
          Toast({
            message: 'wx:' + JSON.toString(res),
            position: 'bottom'
          }); // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
        });
      }
    });
    return {
      useRoute
    };
  },

  components: {}
};