import { createRouter, createWebHashHistory, createWebHistory,af } from "vue-router"
 
const routes = [
    {
        path: '/',
        component: () => import('@/pages')             
    },{
        path: '/test',
        name: "test",
        component: () => import('@/pages/test')   
    },
    {
        path: '/scan',
        name: "scan",
        component: () => import('@/pages/scan')   
    },
    {
        path: '/pickUpGoods',
        name: "pickUpGoods",
        component: () => import('@/pages/pickUpGoods')   
    },
    { 
        title: '扫码页面', 
        name: 'scanCodePage', 
        path: '/scanCodePage', 
        component: () => import('@/pages/scanCodePage') 
    },
    {
       path: '/submitResult',
       name: "submitResult",
       component: () => import('@/pages/submitResult')   
    },
    {
       path: '/pickUpDetails',
       name: "pickUpDetails",
       component: () => import('@/pages/pickUpDetails')   
    }
]
export const router = createRouter({
//   history: createWebHashHistory(),
  history: createWebHistory(),
  routes: routes
})
   // 全局前置守卫
// router.beforeEach((to, from, next) => {
    // console.log("前置守卫+")
    // console.log("前置守卫+to:"+to)
    // console.log(to)
    // console.log("前置守卫+from:"+from)
    // console.log(from)
    // console.log(next)
    // console.log(window.location.href)
    // console.log(window.location.host)
    // console.log(window.location.hostname)
    // if(to.name === "scan"){
    //     // location.href = 
    //     console.log(location.href)
    // }
    // next();
// })
// 全局后置守卫
router.afterEach((to, from) => {
    // 根据进入的页面进行修改title
    console.log("后置守卫+")
    console.log("后置守卫+to:"+to)
    console.log(to)
    console.log("后置守卫+from:"+from)
    console.log(from)
    
})
export default router