import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import { Button, Cell, CellGroup, Icon, Col, Row, Popup, Toast, Checkbox, CheckboxGroup, DropdownMenu, DropdownItem, Loading, Overlay, Empty, NavBar, Field, Form, Picker, Divider, Slider } from 'vant';


const app = createApp(App)
app.use(router)
app.use(Button);
app.use(Cell);
app.use(CellGroup);
app.use(Icon);
app.use(Col);
app.use(Row);
app.use(Popup);
app.use(Toast);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Loading);
app.use(Overlay);
app.use(Empty);
app.use(NavBar);
app.use(Field);
app.use(Form);
app.use(Picker);
app.use(Divider);
app.use(Slider);
 

app.mount("#app")
